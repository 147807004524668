/*
 * Servizio che si occupa della ricerca dei tag
*/
module SearchTagsService {
    let app = angular.module("app");

    app.factory("SearchTagsService", ["$resource", "GlobalApplicationData", ($resource: ng.resource.IResourceService, GlobalApplicationData: any) => {
        let serv = {
            getTagsByIds: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/get-tags-by-ids', {
            }, {
                query: {
                     method: 'GET',
                     isArray: false
                }
            }, {
                cancellable: true
            }),
            getTagById: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/get-tag-by-id', {
            }, {
                query: {
                     method: 'GET',
                     isArray: false
                }
            }, {
                cancellable: true
            }),
            getFlatTagsByParentIds: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/get-flat-tags-by-parent-ids', {
            }, {
                query: {
                     method: 'GET',
                     isArray: false
                }
            }, {
                cancellable: true
            }),
            searchTags: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/search-tags', {
            }, {
                query: {
                     method: 'GET',
                     isArray: false
                }
            }, {
                cancellable: true
            })
        };
        return serv;
    }]);
}